.bot-type-image {
  width: 70vh;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.bot-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  //Panel
  //margin-top: 35px;
  //height: calc(100vh - 35px);
  //overflow: hidden;
  //Panel

}

.modal-dialog {
  .modal-content {

    max-height: 85vh;

    .modal-body-bot {
      height: calc(100% - 47px);
      overflow: auto;
    }
  }
}

.bot-settings {
  display: flex;
  width: 100%;
  padding: 15px;
  justify-content: space-between;
  position: fixed;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.product-scores-2 {
  columns: 2;
}

.product-scores-4 {
  columns: 4;
}

.react-chatbot-kit-chat-container {
  width: 100% !important;
  height: 100vh;
  ////Panel ^^uncomment
  //height: calc(100vh - 70px);
  ////Panel
}

.react-chatbot-kit-chat-inner-container {
}

.react-chatbot-kit-chat-header {
}

.react-chatbot-kit-chat-input-container {
}

.react-chatbot-kit-chat-message-container {
  //Panel
  //height: calc(100vh - 49px - 45px - 75px) !important;
  //Panel vv uncomment
  height: calc(100vh - 49px - 45px) !important;

}

.react-chatbot-kit-chat-input {
}

.react-chatbot-kit-chat-input-form {
}

.react-chatbot-kit-chat-input:disabled {
  background-color: #efefef;
  color: #ccc;
}

.react-chatbot-kit-chat-input:disabled::placeholder {
  color: #a0a0a0;
}

.react-chatbot-kit-chat-input:disabled::-webkit-input-placeholder {
  color: #a0a0a0;
}

.react-chatbot-kit-chat-input:disabled::-moz-placeholder {
  color: #a0a0a0;
}

.react-chatbot-kit-chat-input:disabled:-ms-input-placeholder {
  color: #a0a0a0;
}

.react-chatbot-kit-chat-input:disabled::-ms-input-placeholder {
  color: #a0a0a0;
}

.react-chatbot-kit-chat-btn-send:disabled {
  background-color: #efefef;
  border-top: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
}

.react-chatbot-kit-chat-btn-send {
}

.react-chatbot-kit-chat-btn-send-icon {
}

.anna-bot .react-chatbot-kit-chat-bot-message-container {
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-template-areas: "bot-avatar bot-message";
}

.simple-bot .react-chatbot-kit-chat-bot-message-container {
  width: 60%;
  flex-grow: 1;

  & > .react-chatbot-kit-chat-bot-message {
    width: 100%;
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}


.react-chatbot-kit-chat-bot-avatar {
  grid-area: bot-avatar;
  flex-shrink: 1;
}

.react-chatbot-kit-chat-bot-avatar-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;

  .bot-video-avatar {
    width: 178%;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
  }

  .react-chatbot-kit-chat-bot-avatar-image {
    max-height: 100%;
    object-fit: cover;
    object-position: center;
    flex-shrink: 1;
  }
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
}

@keyframes typingBot {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

:not(.simple-bot) .react-chatbot-kit-chat-bot-message {
  margin-left: 0;
  grid-area: bot-message;
  width: 60%;
  background-color: $theme-color;

  animation: typeIt 320s steps(40) infinite;

  span {
    display: inline-block;
    overflow: hidden;
    white-space: pre-wrap;
  }

}

.product-anotherinfo-wrapper.inBot {
  ul {
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0;
    list-style-type: none;
    max-width: 100%;
  }
}

.react-chatbot-kit-chat-bot-message-container > .react-chatbot-kit-chat-bot-message-container {
  margin: 0;
}

.react-chatbot-kit-chat-bot-message-arrow {
  border-color: $theme-color;
  border-bottom-color: transparent;
  border-top-color: transparent;
}

.react-chatbot-kit-chat-bot-message-arrow {
}

.react-chatbot-kit-chat-bot-loading-icon-container {
}

.chatbot-loader-container {
  #dots {
    display: none;
  }

  display: block;

  &:after {
    content: 'Anna is typing...';
    color: rgba(255, 255, 255, 0.8);
    width: 100px;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    animation: typing 1s steps(5, end) infinite;
  }
}

@keyframes typing {
  from {
    width: 100px
  }
  to {
    width: 120px
  }
}

#chatbot-loader #chatbot-loader-dot1 {
  display: none;
}

#chatbot-loader #chatbot-loader-dot2 {
  display: none;
}

#chatbot-loader #chatbot-loader-dot3 {
  display: none;
}

.react-chatbot-kit-error {
}

.react-chatbot-kit-error-container {
}

.react-chatbot-kit-error-header {
}

.react-chatbot-kit-error-docs {
}

.react-chatbot-kit-user-chat-message {
  width: 40%;
}

.react-chatbot-kit-chat-btn-send {
  border-radius: 0;
  background-color: $theme-color;

  &:hover {
    opacity: 0.7;
  }
}

