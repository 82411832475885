/*-------- 8. Footer style  -----*/

.copyright {
  .footer-logo {
    margin-top: -8px;
    margin-bottom: 20px;
    @media #{$xs-layout} {
      margin-top: 0;
    }
    @media #{$md-layout} {
      margin-top: 0;
    }
    @media #{$lg-layout} {
      margin-top: -8px;
    }
  }
  p {
    letter-spacing: 0.9px;

    color: #313131;
    a {
      color: #313131;
      &:hover {
        color: $theme-color;
      }
    }
  }
}
.layout-three {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  height: 100vh;
  //justify-content: space-between;
  .layout-content {
    flex-grow: 1;
  }
}
