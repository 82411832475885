.buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  &.margin {
    margin-top: 30px !important;
  }

}
button {
  outline: none;
  border: none;
  background: transparent;
  &:not(:last-child) {
    margin-right: 15px;
  }
  &.button-back {
    border: 1px solid #adb5bd;
    border-radius: 3px;
    color: #6c757d;
    width: 130px;
    &:hover {
      color: $white;
      background: #adb5bd;
    }
  }
  &.button-next {
    border: 1px solid $theme-color;
    border-radius: 3px;
    color: $white;
    width: 130px;
    background: $theme-color;
    &:hover {
      opacity: 0.7;
    }
  }
}
.research-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  .consent {
    p:not(:first-of-type) {
      margin-top: 10px;
    }
    h5 {
      margin-top: 20px;
      font-weight: bold;
    }
  }
  .step-container {
    height: 600px
  }

  .input {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    max-height: 38px;
  }

  .form {
    .input {
      width: 300px;
    }
    .form-check {
      .input {
        width: calc(300px - 21px);
      }
    }
  }
  p {
    margin-bottom: 0;
  }
  button.long-button.button-next {
    @media #{$mobile-layout} {
      width: 200px;
    }
    width: 400px;
  }

  .headphones-image {
    width: 10%;
    //height: 200px;
    margin-bottom: 20px;
    @media #{$mobile-layout} {
      width: 100px;
    }
  }
  .battery-image {
    width: 13%;
    @media #{$mobile-layout} {
      width: 50px;
      margin-left: 20px;
    }
  }
  .weight-image {
    width:9%;
    @media #{$mobile-layout} {
      width: 50px;
      margin-left: 20px;
    }
  }
  .noise-image {
    width: 25%;
    height: auto;
    align-self: flex-end;
  }
  .bluetooth-image {
    width: 20%;
    @media #{$mobile-layout} {
      width: 150px;
      margin-left: 20px;
    }
  }
  .frequency-image {
    height: 150px;
    width: auto;
  }
  .connectivity-image {

    max-height: 40px;
    height: 35px;
    // max-width: 15%;
    width: auto;

    @media #{$mobile-layout} {
      max-width: 50%;
      margin-bottom: 20px;
    }
  }
  .foldable-image {
    width: 15%;
    height: auto;
  }
}

