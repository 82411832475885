/*---- 18. Shop page  -------*/

.shop-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media #{$xs-layout} {
    display: block;
  }
  @media #{$sm-layout} {
    display: flex;
  }

  .select-shoing-wrap {
    display: flex;
    @media #{$xs-layout} {
      display: block;
      margin-right: 0;
    }
    @media #{$sm-layout} {
      display: flex;
    }

    .shop-select {
      margin-right: 50px;
      @media #{
      $mobile-layout} {
        margin-right: 0;
      }

      select {
        font-size: 14px;

        min-width: 175px;
        padding: 5px;

        color: #606060;
        border: 1px solid #888888;
      }
    }

    p {
      margin: 0;

      color: #606060;
      @media #{$xs-layout} {
        margin: 5px 0 10px;
      }
      @media #{$sm-layout} {
        margin: 0;
      }
    }
  }

  .shop-tab {
    a,
    button {
      font-size: 18px;

      margin-left: 20px;
      padding: 0;

      color: #606060;
      border: none;
      background: none;

      &:first-child {
        margin-left: 0;
      }

      &.active {
        color: $theme-color;
      }
    }
  }
}

.filter-tags {
  @media #{$xs-layout} {
    width: 34% !important;
  }
  @media #{$sm-layout} {
    width: 32% !important;
  }
  @media #{$md-layout} {
    width: 24% !important;
  }
  @media #{$lg-layout} {
    width: 24% !important;
  }
}

.grid.row.table {
  overflow: hidden;
  width: calc(100% - 12px);
  max-width: calc(100% - 12px);
  flex-shrink: 1;
  overflow-x: scroll;
  position: relative;
  max-height: calc(100vh - 200px - 62px);
  overflow-y: scroll;
  margin: 0 6px;
  //&:after {
  //  content: '';
  //  display: block;
  //  top: 0;
  //  right: 0;
  //  bottom: 0;
  //  width: 25px;
  //  box-shadow: inset -20px 0px 22px 0px rgba(255,255,255,0.9);
  //  position: absolute;
  //  z-index: 4;
  //}
}

.recommended-label-fix {
  .products-table {

    .recommended-model:after {
      left: -82px;
    }
  }
}

.products-table {
  overflow: auto;
  width: 100%;
  max-width: 100%;
  position: relative;

  img {
    max-width: 40px;
  }

  th {
    width: 50px;
    word-wrap: break-word;
    font-size: 12px;
    font-weight: 500;
    padding: 5px;
    line-height: 1.02;
    vertical-align: baseline;
    background-color: transparent;
  }

  td {
    width: 50px;
    word-wrap: break-word;
    font-size: 12px;
    font-weight: 300;
    padding: 5px;
    line-height: 1.02;
    vertical-align: baseline;
    background-color: transparent;

  }

  th.sticky {
    position: sticky;
    left: 0;
    background-color: #fff;
    z-index: 2;

    &:not(:nth-child(1)) {
      left: 69px
    }
  }

  td.sticky {
    position: sticky;
    left: 0;
    background-color: #fff;
    z-index: 1;

    &:not(:nth-child(1)) {
      left: 69px
    }
  }

  .sticky-header {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 3;
  }

  button {
    outline: none;
    border: 1px solid #6060607a;
    border-radius: 2px;
    width: 60px;
    padding: 2px;
    color: #606060;
    font-size: 12px;
    background-color: transparent;
    font-weight: 400;
    cursor: pointer;

    &:hover {
      border: 1px solid #606060;
    }

    &.active {
      background-color: $theme-color;
      color: white;
      border-color: $theme-color;
    }
  }

  .product-model-link {
    text-decoration: underline;
    color: $theme-color;

    & > * {
      color: $theme-color;

    }
  }

  .recommended-model {
    position: relative;

    &:after {
      content: "Recommended";
      background-color: #dc3737;
      color: #fff;
      position: absolute;
      left: -70px;
      top: -19px;
      z-index: 1000;
      border-radius: 2px;
      padding: 2px 6px;
      font-size: 9px;
    }
  }
}

.shop-list-wrap {

  .product-wrap {
    &:hover .hover-img {
      visibility: inherit;

      transform: none;

      opacity: inherit;
    }
  }

  .shop-list-content {
    @media #{$xs-layout} {
      margin-top: 20px;
    }
    @media #{$sm-layout} {
      margin-top: 0;
    }

    h3 {
      font-size: 24px;

      margin: 0;

      color: #010101;
      @media #{$xs-layout} {
        font-size: 20px;
        line-height: 20px;
      }

      a {
        color: #010101;

        &:hover {
          color: $theme-color;
        }
      }
    }

    .product-list-price {
      margin: 13px 0 22px;
      @media #{$xs-layout} {
        margin: 13px 0 13px;
      }
      @media #{$md-layout} {
        margin: 13px 0 19px;
      }

      span {
        font-size: 24px;
        line-height: 1;

        display: inline-block;

        color: #fe5252;

        &.old {
          font-size: 18px;

          margin-left: 15px;

          text-decoration: line-through;

          color: #cfcfcf;
        }
      }
    }

    .rating-review {
      display: flex;
      align-items: center;

      .product-list-rating {
        i {
          font-size: 17px;

          margin: 0 3px 0;

          color: #5f5d5d;

          &.yellow {
            color: #ffa900;
          }
        }
      }

      a {
        font-size: 15px;
        line-height: 1;

        position: relative;

        margin-left: 40px;

        color: #ffcf75;

        &:before {
          position: absolute;
          top: 0;
          left: -21px;

          width: 1px;
          height: 15px;

          content: "";

          background-color: #d1d1d1;
        }
      }
    }

    p {
      font-size: 15px;
      line-height: 28px;

      margin: 15px 0 40px;

      color: #8f8f8f;
      @media #{$xs-layout} {
        margin: 12px 0 20px;
      }
      @media #{$md-layout} {
        margin: 12px 0 20px;
      }
      @media #{$lg-layout} {
        margin: 15px 0 20px;
      }
    }

    .shop-list-btn {
      a,
      button {
        font-size: 14px;
        font-weight: 500;
        line-height: 1;

        z-index: 1;

        display: inline-block;

        padding: 14px 32px 15px;

        text-transform: uppercase;

        color: #fff;
        border: 1px solid transparent;
        background-color: #343538;
        @media #{$xs-layout} {
          font-size: 13px;

          padding: 10px 20px 11px;
        }

        &:hover {
          border: 1px solid $theme-color;
        }
      }
    }
  }

  &:hover .product-wrap .hover-img {
    visibility: visible;

    transform: translate(-50%, 0);

    opacity: 1;
  }
}

.pro-pagination-style {
  @media #{$xs-layout} {
    &.mt-30 {
      margin-top: 10px;
    }
  }

  ul {
    li {
      display: inline-block;

      margin: 0 4px;

      a,
      button {
        font-size: 16px;
        line-height: 43px;

        display: inline-block;

        width: 43px;
        height: 43px;
        padding: 0;

        text-align: center;

        color: $theme-color;
        border: none;
        border-radius: 100% !important;
        background: none;
        box-shadow: 0 0 12px 0.8px rgba(0, 0, 0, 0.1);

        &:hover {
          color: #fff;
          background-color: $theme-color;
        }
      }

      a.active {
        color: #fff;
        background-color: $theme-color;
        box-shadow: none;

        &:hover {
          background-color: #333;
        }
      }

      a.prev,
      a.next {
        font-size: 17px;

        color: $theme-color;
        background-color: #f6f6f6;
        box-shadow: none;

        &:hover {
          color: #fff;
          background-color: $theme-color;
        }
      }

      &.page-item {
        &.active {
          .page-link {
            --bs-pagination-active-color: #ffffff;
            border-color: $theme-color;
            background-color: $theme-color;
            box-shadow: 0 0 12px 0.8px rgba(0, 0, 0, 0.1) !important;

            &:focus {
              box-shadow: none;
            }
          }
        }

        .page-link {
          box-shadow: 0 0 12px 0.8px rgba(0, 0, 0, 0.1) !important;

          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}

@media #{$xs-layout} {
  .shop-area.pb-100 {
    padding-bottom: 45px;
  }
}

.list2-col-style {
  .shop-list-wrap {
    .shop-list-content {
      @media #{$xl-layout} {
        h3 {
          font-size: 20px;
          line-height: 20px;
        }
        .product-list-price {
          margin: 13px 0 10px;
        }
        p {
          margin: 6px 0 20px;
        }
        .shop-list-btn {
          a {
            font-size: 13px;

            padding: 10px 24px 11px;
          }
        }
      }
      @media #{$lg-layout} {
        h3 {
          font-size: 20px;
          line-height: 20px;
        }
        .product-list-price {
          margin: 13px 0 10px;
        }
        p {
          margin: 6px 0 20px;
        }
        .shop-list-btn {
          a {
            font-size: 13px;

            padding: 10px 20px 11px;
          }
        }
      }
    }
  }
}

.grid-sizer {
  width: 25%;
}

.shop-bottom-area {
  width: 100%;
  max-width: 100%;

  .col-xl-4 {
    transition: 0.5s;
  }

  .grid {
    .shop-list-wrap {
      display: none;
    }

    &.two-column {
      .col-xl-4 {
        flex: 1 0 50%;

        max-width: 50%;

        transition: 0.5s;
      }
    }
  }

  .list {
    .product-wrap {
      display: none;
    }

    &.two-column {
      & > .col-xl-4 {
        flex: 1 0 50%;

        max-width: 50%;

        transition: 0.5s;

        @media #{$lg-layout} {
          flex: 1 0 100%;

          max-width: 100%;
        }
        @media #{$md-layout} {
          flex: 1 0 100%;

          max-width: 100%;
        }
        @media #{$xs-layout} {
          flex: 1 0 100%;

          max-width: 100%;
        }
      }
    }

    & > .col-xl-4 {
      flex: 1 0 100%;

      max-width: 100%;

      transition: 0.5s;
    }
  }
}

.shop-list-wishlist,
.shop-list-compare {
  button {
    font-size: 20px;

    padding: 0;

    border: none;
    background: none;

    &:hover,
    &.active {
      color: $theme-color;
    }
  }
}

.shop-area {
  .container-fluid {
    padding: 0 70px;
    @media #{$xl-layout} {
      padding: 0 15px;
    }
    @media #{$lg-layout} {
      padding: 0 30px;
    }
    @media #{$md-layout} {
      padding: 0 40px;
    }
    @media #{$xs-layout} {
      padding: 0 15px;
    }
  }
}
