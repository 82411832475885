@import "~bootstrap/scss/bootstrap";
@import "~react-modal-video/scss/modal-video.scss";
@import "../css/icons.min.css";

@import "variables";
@import "helper";
@import "common";
@import "preloader";
@import "header";
@import "slider";
@import "product";
@import "footer";
@import "image-slider";
@import "breadcrumb";
@import "sidebar";
@import "shop";
@import "cart";
@import "compare";
@import "404";
@import "research";
@import "chatbot";
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}


